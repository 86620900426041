@import
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert";

.modal {
  display: none;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  @include mq(md) {
    align-items: center;
  }

  &.is-open {
    display: flex;

    .modal__backdrop {
      animation:
        fadeIn .25s cubic-bezier(0.0, 0.0, 0.2, 1) forwards;
    }

    .modal__container {
      animation:
        fadeIn .25s cubic-bezier(0.0, 0.0, 0.2, 1) forwards,
        scaleIn .3s cubic-bezier(0.0, 0.0, 0.2, 1) forwards;
    }
  }

  /*
    The selector depth must be here at 2,
    so that styles of 'typo-container *' can be overwritten.
  */
  .modal {
    &__backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
    }

    &__container {
      position: relative;
      z-index: 2000;
      margin: 1em 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      transform: scale(.8);
      opacity: 0;
    }

    &__surface {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      box-shadow: 2px 2px 8px rgba(0,0,0,.2);
      background-color: #fff;
      min-width: 200px;
      max-width: 90vw;
      //max-height: 90vh;
      @include mq(md) {
        max-width: 560px;
        max-height: 80vh;
      }

    }

    &__header {
      position: relative;
      padding: var(--space-sm) var(--space-md);
    }

    &__media {
      margin: 1em auto;
      max-width: 200px;

      path {
        fill: var(--color-primary);
      }
    }

    &__title {
      margin: 0;
    }

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      padding: var(--space-sm);

      .ico {
        pointer-events: none;
      }
    }

    &__content {
      flex-grow: 1;
      overflow: auto;
      padding: var(--space-sm) var(--space-md);
      color: var(--color-text-meta);
    }

    &__footer {
      display: flex;
      position: relative;
      flex-shrink: 0;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      min-height: 60px;
      padding: var(--space-sm) var(--space-md);
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &__buttons {
      .btn {
        &:not(:last-child){
          margin-right: var(--space-sm);
        }
      }
    }

    &__icons {
      .btn {
        &:not(:last-child){
          margin-right: var(--space-md);
        }
      }
    }

  }
}

/*
Modals are hidden with 'display: none' and can not be animated with css.
A simultaneous fade in and animate is not possible
but '@keyframes' make this possible.
*/
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.8); }
    to { transform: scale(1); }
}
